@font-face {
  font-family: 'LOWBATT';
  src: url('./lowbatt.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
} 

@font-face {
  font-family: 'Futur';
  src: url('./futur.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
} 