.fenHistoryContainer {
  width: 320px;
  margin: 0 0 0 20px;
  border-radius: 8px;
  background-color: #f8f8f8;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  padding: 10px;
  align-self: flex-start;
}

.fenHistoryContainer h3 {
  margin-top: 0;
  padding-bottom: 8px;
  border-bottom: 1px solid #e0e0e0;
  color: #333;
  font-size: 1.1rem;
  text-align: center;
}

.scrollBox {
  height: 400px;
  overflow-y: auto;
  scrollbar-width: thin;
  padding: 5px;
}

.scrollBox::-webkit-scrollbar {
  width: 6px;
}

.scrollBox::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 3px;
}

.scrollBox::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 3px;
}

.scrollBox::-webkit-scrollbar-thumb:hover {
  background: #a8a8a8;
}

.fenEntry {
  padding: 8px 6px;
  border-bottom: 1px solid #eaeaea;
  font-family: 'Courier New', monospace;
  display: flex;
  align-items: flex-start;
  position: relative;
  cursor: pointer;
  transition: background-color 0.2s;
}

.fenEntry:hover {
  background-color: #f0f0f0;
}

.fenEntry:last-child {
  border-bottom: none;
}

.selected {
  background-color: rgba(25, 118, 210, 0.08);
}

.selected:hover {
  background-color: rgba(25, 118, 210, 0.12);
}

.moveNumber {
  min-width: 40px;
  margin-right: 8px;
  color: #666;
  font-weight: bold;
  flex-shrink: 0;
}

.moveContent {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
}

.simplified {
  font-family: Arial, sans-serif;
  color: #222;
  font-size: 0.9rem;
  margin-bottom: 4px;
}

.fenNotation {
  word-break: break-all;
  white-space: pre-wrap;
  color: #aaaaaa;
  font-size: 0.7rem;
  max-height: 2.5em;
  overflow: hidden;
  text-overflow: ellipsis;
  font-style: italic;
}

.selected .fenNotation {
  max-height: none;
}

/* Previous game result styling */
.previousGame {
  background-color: rgba(255, 215, 0, 0.15);
  border-left: 3px solid #ffa500;
  padding-left: 10px;
}

.previousGame:hover {
  background-color: rgba(255, 215, 0, 0.25);
}

.previousGame .simplified {
  font-weight: bold;
  color: #8b4513;
}

/* Highlight current move */
.fenEntry:last-child {
  background-color: rgba(255, 248, 220, 0.5);
}

.fenEntry:last-child:hover {
  background-color: rgba(255, 248, 220, 0.7);
}

.moveDetails {
  margin-top: 8px;
  padding: 8px;
  background-color: rgba(0, 0, 0, 0.03);
  border-radius: 4px;
  font-size: 0.85rem;
  width: 100%;
}

.detailsHeader {
  font-weight: bold;
  margin-bottom: 6px;
  color: #444;
  font-family: Arial, sans-serif;
}

.detailsContent {
  word-break: break-all;
  white-space: pre-wrap;
  font-family: 'Courier New', monospace;
} 