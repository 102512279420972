.container {
    display: flex;
    padding: 20px;
    justify-content: flex-start;
}

.gameControls {
    display: flex;
    flex-direction: column;
    margin: 0 20px 20px 0;
    min-width: 200px;
    order: 1; /* Ensure this appears first (left side) */
}

.gameContent {
    display: flex;
    flex-direction: row; /* Changed from column to row */
    align-items: flex-start;
    margin: 0;
    order: 2; /* This appears after gameControls */
}

.boardWrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin: 0 20px 20px 0;
}

.debug {
    display: block;
    padding-left: 20px;
    order: 3; /* This appears last */
}

.pieceSelection {
    margin-right: 20px;
    min-width: 210px;
    /* Now pieces will be on the left of the board */
}

.pieceIcons {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Changed to 3 columns */
    grid-template-rows: repeat(4, auto);   /* Adjusted rows */
    gap: 10px;
    margin-top: 20px;
}

.pieceIconContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.pieceIcon {
    width: 60px; /* Slightly smaller to fit 3 columns */
    height: 60px;
}

.howToPlayLink {
    display: inline-block;
    padding: 8px 16px;
    background-color: #4CAF50;
    color: white;
    text-decoration: none;
    border-radius: 4px;
    font-weight: bold;
    margin: 5px 0;
    transition: background-color 0.3s;
}

.howToPlayLink:hover {
    background-color: #45a049;
}

/* Strategy selector styles */
.strategySelector {
    margin-top: 20px;
    padding: 15px;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    width: 100%;
}

.strategySelector p {
    margin: 5px 0;
    font-weight: bold;
}

.strategyButtons {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin: 10px 0;
}

.strategyButton {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px 12px;
    background-color: #555;
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.2s;
    flex: 1;
    min-width: 70px;
}

.strategyButton span[role="img"] {
    font-size: 24px;
    margin-bottom: 5px;
}

.strategyName {
    font-size: 12px;
}

.strategyButton:hover {
    background-color: #777;
    transform: translateY(-2px);
}

.activeStrategy {
    background-color: #3273dc;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.strategyDescription {
    margin-top: 10px;
    font-size: 14px;
    color: #555;
    font-style: italic;
}

/* Active Heuristic display in debug console */
.activeHeuristic {
  margin-top: 8px;
  padding: 8px;
  background-color: rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  display: inline-block;
}

.heuristicName {
  font-weight: bold;
  color: #3273dc;
  background-color: rgba(50, 115, 220, 0.1);
  padding: 2px 6px;
  border-radius: 4px;
}

/* Neural Network Testing Table */
.nnTestingTable {
  margin: 15px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
}

.nnTestingTable h3 {
  background-color: #4a1e9e;
  color: white;
  margin: 0;
  padding: 8px 12px;
  font-size: 16px;
}

.nnTestingTable table {
  width: 100%;
  border-collapse: collapse;
}

.nnTestingTable td {
  padding: 6px 12px;
  border-bottom: 1px solid #eee;
}

.nnTestingTable tr:last-child td {
  border-bottom: none;
}

.nnTestingTable tr:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.05);
}

.nnTestingTable td:first-child {
  font-weight: bold;
  width: 50%;
}

.nnActionButton {
  background-color: #2196F3;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 4px 8px;
  cursor: pointer;
  font-weight: bold;
  font-size: 0.9em;
  transition: background-color 0.3s;
}

.nnActionButton:hover {
  background-color: #0b7dda;
}

.nnActionButton:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}