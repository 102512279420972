.pageContainer {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Arial, sans-serif;
  color: white;
  background-color: #000;
}

.headerContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 20px 0;
  margin-bottom: 20px;
  background-color: #000;
}

.header {
  width: 100%;
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}

.headerControls {
  display: flex;
  align-items: center;
  gap: 15px;
}

.volumeButton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background-color: transparent;
  color: white;
  text-decoration: none;
  border: none;
  border-radius: 4px;
  transition: all 0.3s;
  cursor: pointer;
  height: 40px;
}

.volumeButton:hover {
  transform: scale(1.1);
  background-color: transparent;
}

.volumeIcon {
  width: 32px;
  height: 32px;
  filter: drop-shadow(0 0 5px rgba(0, 120, 255, 0.7));
  transition: all 0.3s;
}

.volumeButton:hover .volumeIcon {
  filter: drop-shadow(0 0 10px rgba(0, 140, 255, 0.9));
}

.container {
  width: 60%;
  margin: 0 auto;
  padding: 20px;
  background-image: url('./backsky6.jpg');
  background-size: cover;
  background-position: center;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 0 30px rgba(0, 100, 255, 0.3);
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

/* Large transparent background image */
.largeBackgroundImage {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  pointer-events: none;
  overflow: hidden;
  mask-image: linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 1) 15%, rgba(0, 0, 0, 1) 85%, transparent 100%);
  -webkit-mask-image: linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 1) 15%, rgba(0, 0, 0, 1) 85%, transparent 100%);
}

.transparentImage {
  width: 105%;
  height: auto;
  opacity: 0.08;
  transform: rotate(90deg);
  position: absolute;
  left: -2.5%;
  animation: floatUpDown 1200s linear infinite;
  /* Initial position will be set inline with the top property */
}

.transparentImageNoRotate {
  width: auto;
  height: 125%;
  opacity: 0.08;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  animation: floatUpDownNoRotate 1200s linear infinite;
  /* Initial position will be set inline with the top property */
}

.transparentImageFrog {
  width: auto;
  height: 150%;
  opacity: 0.08;
  transform: rotate(90deg);
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  animation: floatUpDownNoRotate 1200s linear infinite;
  /* Initial position will be set inline with the top property */
}

.transparentImagePenguin {
  width: auto;
  height: 125%;
  opacity: 0.05; /* More transparent than the standard 0.08 */
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  animation: floatUpDownNoRotate 1200s linear infinite;
  /* Initial position will be set inline with the top property */
}

.transparentImageMonkey {
  width: auto;
  height: 175%; /* 50% bigger than the standard 125% */
  opacity: 0.08;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  animation: floatUpDownNoRotate 1200s linear infinite;
  /* Initial position will be set inline with the top property */
}

@keyframes floatUpDown {
  from {
    transform: rotate(90deg);
    /* The top value is set inline and will be the starting point */
  }
  to {
    transform: rotate(90deg);
    top: -150%; /* When animation plays normally, moves up */
    /* When animation plays in reverse, it will move down from the starting point */
  }
}

@keyframes floatUpDownNoRotate {
  from {
    /* The top value is set inline and will be the starting point */
  }
  to {
    top: -150%; /* When animation plays normally, moves up */
    /* When animation plays in reverse, it will move down from the starting point */
  }
}

.logoImage {
  height: 80px;
  object-fit: contain;
  filter: drop-shadow(0 0 15px rgba(0, 100, 255, 0.7));
  transition: all 0.3s ease;
}

.logoImage:hover {
  transform: scale(1.05);
  filter: drop-shadow(0 0 20px rgba(0, 150, 255, 0.9));
}

.backButton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 0 16px;
  background-color: rgb(0, 128, 255);
  color: white;
  text-decoration: none;
  border-radius: 4px;
  font-family: 'LOWBATT', 'Impact', 'Arial Black', sans-serif;
  font-weight: normal;
  font-size: 1.2rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: all 0.3s;
  text-shadow: 0 0 5px rgba(0, 200, 255, 0.5);
}

.backButton:hover {
  background-color: rgba(0, 180, 255, 0.9);
  transform: scale(1.05);
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.7);
}

.content {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0;
  position: relative;
  z-index: 2;
}

.sidebar {
  flex: 0 0 250px;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 20px;
  border-radius: 8px;
  backdrop-filter: blur(5px);
}

.sidebar h2 {
  margin-top: 0;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  color: white;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.sidebar li {
  margin-bottom: 8px;
}

.sectionButton {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px 12px;
  background-color: transparent;
  border: none;
  border-radius: 4px;
  text-align: left;
  cursor: pointer;
  transition: all 0.2s;
  color: white;
}

.sectionButton:hover {
  background-color: rgba(255, 255, 255, 0.1);
  transform: translateX(5px);
}

.pieceIcon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.mainContent {
  width: 100%;
  max-width: 900px;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  position: relative;
  z-index: 3;
  min-height: 600px;
  display: flex;
  flex-direction: column;
}

/* Remove shadow box for What is? section */
.mainContent h2 {
  text-align: center;
}

.sectionContent {
  animation: fadeIn 0.3s ease-in-out;
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.pieceSection {
  margin-bottom: 20px;
  height: 600px;
  display: flex;
  flex-direction: column;
}

.pieceHeader {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}

.pieceLargeIcon {
  width: 80px;
  height: 80px;
  margin-right: 20px;
}

.welcome {
  text-align: center;
}

.iconsContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  position: relative;
  z-index: 2;
}

.pieceGrid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  margin: 20px auto;
  max-width: 900px;
}

.pieceCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: transparent;
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
}

.pieceCard:hover {
  transform: translateY(-8px) scale(1.05);
  background-color: transparent;
}

.pieceCardIcon {
  width: 100px;
  height: 100px;
  margin-bottom: 10px;
  transition: all 0.3s;
  opacity: 0.5;
  filter: drop-shadow(0 0 5px rgba(0, 100, 255, 0.3));
}

.pieceCard:hover .pieceCardIcon {
  transform: scale(1.1);
  opacity: 1;
  filter: none;
}

.pieceDescription {
  margin-top: 20px;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.0);
  border-radius: 8px;
  box-shadow: none;
  font-size: 1.1rem;
  line-height: 1.6;
  position: absolute;
  bottom: 60px;
  left: 20px;
  right: 20px;
}

.pieceDescription p {
  margin: 0;
  text-align: center;
  font-weight: 500;
  color: white;
}

.aboutSection {
  line-height: 1.6;
  text-align: center;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Carousel Styles */
.carouselContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 30px 0;
  position: relative;
  height: 400px;
}

.carouselImageContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 100%;
}

.carouselImage {
  max-width: 100%;
  max-height: 350px;
  border-radius: 8px;
  object-fit: contain;
}

.carouselButton {
  background-color: rgba(0, 100, 255, 0.5);
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s;
  z-index: 2;
  margin: 0 10px;
  box-shadow: 0 0 15px rgba(0, 100, 255, 0.5);
  padding: 8px;
}

.carouselButton:hover {
  background-color: rgba(0, 100, 255, 0.8);
  transform: scale(1.1);
}

.arrowLeft, .arrowRight {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.arrowLeft {
  transform: rotate(180deg);
}

.imageCounter {
  margin-top: 10px;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.8);
}

.backToIconsButton {
  display: block;
  margin: 20px auto 0;
  padding: 10px 20px;
  background-color: rgba(0, 150, 255, 0.8);
  border: none;
  border-radius: 8px;
  color: white;
  font-family: 'LOWBATT', 'Impact', 'Arial Black', sans-serif;
  font-weight: normal;
  font-size: 1.2rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
  transition: all 0.3s;
  text-shadow: 0 0 5px rgba(0, 200, 255, 0.5);
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}

.backToIconsButton:hover {
  background-color: rgba(0, 180, 255, 0.9);
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.7);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .container {
    width: 90%;
  }

  .content {
    padding: 0;
  }

  .pieceGrid {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }

  .pieceCardIcon {
    width: 100px;
    height: 100px;
  }

  .logoImage {
    height: 60px;
  }

  .carouselButton {
    width: 30px;
    height: 30px;
    padding: 6px;
  }

  .carouselImage {
    max-height: 300px;
  }
  
  .centerText {
    font-size: 5rem;
    letter-spacing: 3px;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .container {
    width: 70%;
  }

  .pieceGrid {
    grid-template-columns: repeat(3, 1fr);
  }
  
  .centerText {
    font-size: 6rem;
  }
}

.centerText {
  position: absolute;
  top: 49%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'LOWBATT', 'Impact', 'Arial Black', sans-serif;
  font-size: 10rem;
  color: rgba(80, 200, 255, 0.557);
  text-align: center;
  z-index: 3;
  pointer-events: none;
  text-transform: uppercase;
  letter-spacing: 5px;
  white-space: nowrap;
  text-shadow: 0 0 25px rgba(0, 180, 255, 0.6), 
               0 0 2px rgba(255, 255, 255, 0.284);
  transition: opacity 0.7s ease, color 0.7s ease;
}

.defaultCenterText {
  opacity: 0;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .container {
    width: 95%;
  }

  .content {
    padding: 0;
  }

  .pieceGrid {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }

  .pieceCardIcon {
    width: 100px;
    height: 100px;
  }

  .logoImage {
    height: 60px;
  }

  .carouselButton {
    width: 30px;
    height: 30px;
    padding: 6px;
  }

  .carouselImage {
    max-height: 300px;
  }
  
  .centerText {
    font-size: 5rem;
    letter-spacing: 3px;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .container {
    width: 80%;
  }

  .pieceGrid {
    grid-template-columns: repeat(3, 1fr);
  }
  
  .centerText {
    font-size: 6rem;
  }
}